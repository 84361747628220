import ApolloClient from "apollo-boost";
import fetch from "isomorphic-fetch";
import { getUser } from "@services/auth";

const API_URL = process.env.API_URL;

export const client = new ApolloClient({
  request: operation => {
    const token = getUser().token;
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    });
  },
  uri: `${API_URL}/graphql`,
  fetch
});
